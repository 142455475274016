import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import {HeartSVG, CommentSVG, PlaneSVG, BookmarkSVG} from './svg';
import {SelfieReflection, SeeingSurveillance} from './interruptions';
import $ from 'jquery';

var mount = document.getElementById('root');

function changer(theState, value) {
  this.state.theState = value;
}

class Feed extends Component {

  constructor(props) {
      super(props)
      this.state = {
          pulsate: "pulsate"
      }
      this.handleScroll=this.handleScroll.bind(this);
  }

  handleScroll(event) {
    this.setState({pulsate: "hide"});
  };

  render() {
    return (
      <div className={`pageWrap ${this.state.pulsate}`}>
      	<div className="cell-phone-wrapper" onScroll={this.handleScroll.bind(this)}>
          <span className="cell-top">
            <span className="speaker"></span>
          </span>
          <div id="scroller" className="cell-phone-screen-wrapper">
            <InstagramPosts />
          </div>
          <span className="cell-bottom">
            <span className="button"></span>
          </span>
        </div>
      </div>
    );
  }
}

export class InstagramPosts extends Component {

  state = {
    posts: [],
    visibility: "hidden"
  }

  componentDidMount() {

    var token = "3951869.da06fb6.5208e2d484c8446d8405b302d84e1e3f";
    var hashtag = "meme";
    var num_photos = 10;
    var smallHash = hashtag.toLowerCase();
    var jsonUrl = "https://www.instagram.com/explore/tags/" + smallHash + "/?__a=1";
    var stateArray = [];

    function shuffleArray(array) {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    axios.get(jsonUrl).then(res => {
      var length = res.data.graphql.hashtag.edge_hashtag_to_media.edges.length;
      var max = 100;
      var count = length > max ? max : length;
      const results = res.data.graphql.hashtag.edge_hashtag_to_media.edges;

      results.forEach(function(result, index){
        if(index+1 <= count) {
          var c = result.node;
          var per = Math.round(count * .75);

          if(index === per) {
            c.interrupt = true;
          }else{
            c.interrupt = false;
          }

          stateArray.push(c);
        }
      });

      this.setState({
        posts: stateArray,
        visibility: "visible"
      });
    });

    // changer("loading", "loaded");

  }

  render() {
    return (
      <ul id="posts" className={this.state.visibility} >
      {this.state.posts.map(function(post,i){

        var shortcode = post.shortcode;
        var type = post.__typename;
        var img = post.display_url;
        var alt = post.accessibility_caption;

        // console.log(post);

        if(post.interrupt === true){
          return <Interruptor key={"interruption"} />
        }else{
          return <SinglePost 
              key={shortcode} 
              type={type}
              image={img}
              alt={alt}
            />
        }
      })}
      </ul>
    )
  }
}

class SinglePost extends Component {
  render() {
    return (
      <li key={this.key}>
      <div className="feed-item image-item" className={`feed-item image-item ${this.props.type}`} >
        <div className="user-bar">
          <div className="user-icon"><span></span></div>
          <div className="user-handle">someone_u_follow</div>
          <div className="some-circles">• • •</div>
        </div>
        <div className="post-image"><img src={this.props.image} alt={this.props.alt ? this.props.alt : "A distracting meme post"} /></div>
        <div className="icons-row">
          <span className="like"><HeartSVG /></span>
          <span className="comment"><CommentSVG /></span>
          <span className="dm"><PlaneSVG /></span>
          <span className="save"><BookmarkSVG /></span>
        </div>
      </div>
    </li>
    )
  }
}
 
class Interruptor extends Component {

  constructor(props) {
      super(props)
      this.state = {
          theHeight: document.getElementsByClassName('cell-phone-screen-wrapper')[0].clientHeight,
      }
  }

  render() {

    $('#scroller').scroll(function(){
      // This is then function used to detect if the element is scrolled into view
      
      var screenTop = $('#scroller').offset().top;
      var screenHeight = $('#scroller').height();

      var screenPoint = screenTop + screenHeight;
      var interruptorTop = $('#interruptor').offset().top;      

      if(interruptorTop < screenPoint) {
        var contactTopPosition = $("#interruptor.active").position().top;
        $("#scroller").animate({scrollTop: contactTopPosition});
      }

    });

    var hashtag = window.location.hash;
    var interruption;
    var the_interruptor = document.getElementById('interruptor');

    
    if(hashtag === "#surveillance") {
      interruption = (<SeeingSurveillance />);
    }else{
      interruption = (<SelfieReflection />);
    }

    window.addEventListener('resize', function(event){
      this.setState = {
        theHeight: document.getElementsByClassName('cell-phone-screen-wrapper')[0].clientHeight
      }
    });

    const the_height = this.state.theHeight;

    return (
      <li id="interruptor" className="interruptor active" style={{height: the_height}} >
        {interruption}
      </li>
    );
  }
}

ReactDOM.render(<Feed />, mount);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
