import React, { Component } from 'react';


class NavButton extends Component {

  clicker() {
    stepChange(this.props.next);
  }

  render() {
    return (
      <button className={this.props.classer} onClick={this.clicker.bind(this)}>{this.props.text}</button>
    );
  }

}

function stepChange(nextStep) {
    this.setState({
      step: nextStep
    });
}

 
class InterruptionStart extends Component {
  render() {
    return (
      <div className="interruption-wrap">
        <p>Pardon this interruption. We want to remind you that it is important to use technology responsibly.</p>
        <p>Just to make sure, we should go through an exercise together.</p>
      </div>
    );
  }
}

class StepDesc extends Component {
  render() {
    return (
      <div className="desc-step">
        <h2>{this.props.title}</h2>
        <img src={this.props.image} />
        <p>{this.props.description}</p>
      </div>
    );
  }
}

 
class ExerciseText extends Component {
  
  constructor(props) {
      super(props)

      const startTime = 15;

      var timeLeft = setInterval(function(){
        var time = startTime;
        var time = time - 1;
        return time;
      }, 1000);

      this.state = {
          exStep: 'exStep-1',
          timeleft: timeLeft
      }

     
  }

  render() {

    var start_state = 1;

    return (
      <div className="exercise-steps">
        <div className={this.state.exStep}>
          <span className="exStep-text-1">{this.props.exStepText1}</span>
          <span className="exStep-text-2">{this.props.exStepText2}</span>
          <span className="exStep-text-3">{this.props.exStepText3}</span>
          <span className="exStep-text-4">{this.props.exStepText4}</span>
          <span className="exStep-text-5">{this.props.exStepText5}</span>
          <span className="exStep-text-6">{this.props.exStepText6}</span>
          <span className="exStep-text-7">{this.props.exStepText7}</span>
          <span className="exStep-text-8">{this.props.exStepText8}</span>
          <span className="exStep-text-9">{this.props.exStepText9}</span>
          <span className="exStep-text-10">{this.props.exStepText10}</span>
        </div>
        <div className="countdown">{this.state.timeleft}</div>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// SELFIE REFLECTION
///////////////////////////////////////////////////////////////////////////////////////////////////

export class SelfieReflection extends Component {

  constructor(props) {
      super(props)
      this.state = {
          step: "camera"
      }
      stepChange=stepChange.bind(this);
  }

  render() {

    var stepper;

    console.log(this.state.step);

    if(this.state.step === "start") {
      stepper = (
        <div className="int-wrap">
          <InterruptionStart />
          <NavButton classer="continue" text="CONTINUE" next="description" />
        </div>
      );
    }else if(this.state.step === "description"){
      var img = '/images/selfie_reflection.png';
      stepper = (
        <div className="int-wrap">
          <StepDesc title="This is Selfie Reflection" image={img} description="With all of the digital distractions out there, it's easy to forget about number 1. I'm looking at you. You should be too." />
          <NavButton classer="continue" text="CONTINUE" next="camera_permission" />
        </div>
      );
    }else if(this.state.step === "camera_permission"){
      stepper = (
        <div className="int-wrap">
          <p>Let's take a selfie!</p>
          <p>May we access your phone's camera?</p>
          <NavButton classer="continue" text="YES, CONTINUE" next="camera" />
        </div>
      );
    }else if(this.state.step === "camera"){
      stepper = (
        <div className="int-wrap video">
          <div className="video-wrap">
            <video id="video" width="640" height="480" autoPlay>
              <source src="movie.mp4" />
            </video>
            <ExerciseText 
              exStepText1 = "Step Text 1"
              exStepText2 = "Step Text 2"
              exStepText3 = "Step Text 3"
              exStepText4 = "Step Text 4"
              exStepText5 = "Step Text 5"
              exStepText6 = "Step Text 6"
              exStepText7 = "Step Text 7"
              exStepText8 = "Step Text 8"
              exStepText9 = "Step Text 9"
              exStepText10 = "Step Text 10"
            />
          </div>
        </div>
      );

      if (window.location.protocol === 'https:'){

        var video = document.getElementById('video');

        if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Not adding `{ audio: true }` since we only want video now
          navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
              //video.src = window.URL.createObjectURL(stream);
              video.srcObject = stream;
              video.play();
          });
        }
      }

    }else if(this.state.step === "completion"){
      stepper = (
        <div className="int-wrap">
          <p>Alright, looking good!</p>
          <p>You can continue to your regularly scheduled instagram feed.</p>
          <NavButton classer="continue" text="BACK TO MY FEED" next="close" />
        </div>
      );
    }
    return (
      <div>
      	{stepper}
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// SEEING SURVEILLANCE
///////////////////////////////////////////////////////////////////////////////////////////////////

export class SeeingSurveillance extends Component {

  constructor(props) {
      super(props)
      this.state = {
          step: "camera"
      }
      stepChange=stepChange.bind(this);
  }

  render() {

    var stepper;

    console.log(this.state.step);

    if(this.state.step === "start") {
      stepper = (
        <div className="int-wrap">
          <InterruptionStart />
          <NavButton classer="continue" text="CONTINUE" next="description" />
        </div>
      );
    }else if(this.state.step === "description"){
      var img = '/images/seeing_surveillance.png';
      stepper = (
        <div className="int-wrap">
          <StepDesc title="This is Seeing Surveillance" image={img} description="With so many digital distractions, it's difficult to stay in the moment. There are thousands of live surveillance feeds in the world. We should check one out." />
          <NavButton classer="continue" text="CONTINUE" next="camera_permission" />
        </div>
      );
    }else if(this.state.step === "camera_permission"){
      stepper = (
        <div className="int-wrap">
          <p>Let's take a selfie!</p>
          <p>May we access your phone's camera?</p>
          <NavButton classer="continue" text="YES, CONTINUE" next="camera" />
        </div>
      );
    }else if(this.state.step === "camera"){
      stepper = (
        <div className="int-wrap video">
          
            <iframe src="http://140.114.188.219/mjpg/video.mjpg#.XI6hI4TyvOI.link" scrolling="no" />
            <ExerciseText 
              exStepText1 = "Step Text 1"
              exStepText2 = "Step Text 2"
              exStepText3 = "Step Text 3"
              exStepText4 = "Step Text 4"
              exStepText5 = "Step Text 5"
              exStepText6 = "Step Text 6"
              exStepText7 = "Step Text 7"
              exStepText8 = "Step Text 8"
              exStepText9 = "Step Text 9"
              exStepText10 = "Step Text 10"
            />
        </div>
      );
      
    }else if(this.state.step === "completion"){
      stepper = (
        <div className="int-wrap">
          <p>Alright, looking good!</p>
          <p>You can continue to your regularly scheduled instagram feed.</p>
          <NavButton classer="continue" text="BACK TO MY FEED" next="close" />
        </div>
      );
    }

    return (
      <div>
        {stepper}
      </div>
    );
  }
}