import React, { Component } from 'react';

export class HeartSVG extends Component {
  render() {
    return (
    	<span className="svg-wrap">
      		<svg viewBox="0 0 471.701 471.701"><path fill="#262626" d="M433.601 67.001c-24.7-24.7-57.4-38.2-92.3-38.2s-67.7 13.6-92.4 38.3l-12.9 12.9-13.1-13.1c-24.7-24.7-57.6-38.4-92.5-38.4-34.8 0-67.6 13.6-92.2 38.2-24.7 24.7-38.3 57.5-38.2 92.4 0 34.9 13.7 67.6 38.4 92.3l187.8 187.8c2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-3.9l188.2-187.5c24.7-24.7 38.3-57.5 38.3-92.4.1-34.9-13.4-67.7-38.1-92.4zm-19.2 165.7l-178.7 178-178.3-178.3c-19.6-19.6-30.4-45.6-30.4-73.3s10.7-53.7 30.3-73.2c19.5-19.5 45.5-30.3 73.1-30.3 27.7 0 53.8 10.8 73.4 30.4l22.6 22.6c5.3 5.3 13.8 5.3 19.1 0l22.4-22.4c19.6-19.6 45.7-30.4 73.3-30.4 27.6 0 53.6 10.8 73.2 30.3 19.6 19.6 30.3 45.6 30.3 73.3.1 27.7-10.7 53.7-30.3 73.3z"/></svg>
      	</span>
    );
  }
}

export class CommentSVG extends Component {
  render() {
    return (
    	<span className="svg-wrap">
      		<svg viewBox="0 0 612 612"><g data-name="Group 67"><path data-name="Path 173" d="M210.375 325.125h191.25a19.125 19.125 0 0 1 0 38.25h-191.25a19.125 19.125 0 1 1 0-38.25zm-38.25-114.75h267.75a19.125 19.125 0 0 1 0 38.25h-267.75a19.125 19.125 0 0 1 0-38.25zM306 0c168.988 0 306 119.875 306 267.75 0 84.514-44.848 159.751-114.75 208.826V612L363.2 530.661A350.911 350.911 0 0 1 306 535.5C136.992 535.5 0 415.625 0 267.75S136.992 0 306 0zm0 497.25a309.492 309.492 0 0 0 64.643-7.019l90.041 54.123-1.205-88.7c69.021-41.52 114.271-110.14 114.271-187.9 0-126.741-119.875-229.5-267.75-229.5S38.25 141.009 38.25 267.75 158.125 497.25 306 497.25z" fill="#262626"/><path data-name="Rectangle 10" fill="#fff" d="M126 174h348v197H126z"/></g></svg>
      	</span>

    );
  }
}

export class PlaneSVG extends Component {
  render() {
    return (
    	<span className="svg-wrap">
      		<svg viewBox="0 0 469.038 469.038"><path fill="#262626" d="M465.023 4.079c-3.9-3.9-9.9-5-14.9-2.8l-442 193.7c-4.7 2.1-7.8 6.6-8.1 11.7s2.4 9.9 6.8 12.4l154.1 87.4 91.5 155.7c2.4 4.1 6.9 6.7 11.6 6.7h.8c5.1-.3 9.5-3.4 11.6-8.1l191.5-441.8c2.2-5.1 1.1-11-2.9-14.9zm-70.3 50.9l-226.2 224.7-124.9-70.8 351.1-153.9zm-132.5 370.6l-74.5-126.9 227.5-226-153 352.9z"/></svg>
      	</span>
    );
  }
}

export class BookmarkSVG extends Component {
  render() {
    return (
      <span className="svg-wrap">
        <svg version="1.1" x="0px" y="0px" viewBox="0 0 431.972 431.972">
          <g>
            <path d="M393.146,14.279c-3.713-5.333-8.713-9.233-14.989-11.707c-3.997-1.711-8.186-2.568-12.565-2.568V0H66.378
              c-4.377,0-8.562,0.857-12.56,2.568c-6.28,2.472-11.278,6.377-14.989,11.707c-3.71,5.33-5.568,11.228-5.568,17.701v368.019
              c0,6.475,1.858,12.371,5.568,17.706c3.711,5.329,8.709,9.233,14.989,11.704c3.994,1.711,8.183,2.566,12.56,2.566
              c8.949,0,16.844-3.142,23.698-9.418l125.91-121.062l125.91,121.065c6.663,6.081,14.562,9.127,23.695,9.127
              c4.76,0,8.948-0.756,12.565-2.279c6.276-2.471,11.276-6.375,14.989-11.711c3.71-5.328,5.564-11.225,5.564-17.699V31.98
              C398.71,25.507,396.852,19.609,393.146,14.279z M362.166,391.139L241.397,275.224l-25.411-24.264l-25.409,24.264L69.809,391.139
              V36.549h292.357V391.139L362.166,391.139z" fill="#262626"/>
          </g>
        </svg>
      </span>
    );
  }
}